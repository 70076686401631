import React from "react";
import cs from "classnames";
import styles from "./Footer.module.css";
import { MAIL_TO, URL_FRANCOIS } from "../../constants";

export default function Footer() {
  return (
    <footer>
      <div className="text-white bg-gray-900">
        <div className="container grid grid-cols-12 py-16">
          <div className="col-span-12 mb-8 md:col-span-6 lg:col-span-2 lg:mb-0">
            <div className="text-xl font-bold uppercase">DACSO</div>
            <p className="text-xs opacity-75">© 2020 Tous droits réservé</p>
          </div>
          <div className="col-span-12 mb-8 md:col-span-6 lg:col-span-3 lg:mb-0">
            <h3 className="mb-6 font-bold tracking-widest uppercase opacity-25">
              Menu
            </h3>
            <ul className="space-y-2 text-sm font-bold">
              <li>
                <a
                  className="underline transition duration-300 ease-in-out pointer hover:text-red-500"
                  href="#qui-sommes-nous"
                >
                  Qui sommes nous
                </a>
              </li>
              <li>
                <a
                  className="underline transition duration-300 ease-in-out pointer hover:text-red-500"
                  href="#expertises"
                >
                  Expertises
                </a>
              </li>
              <li>
                <a
                  className="underline transition duration-300 ease-in-out pointer hover:text-red-500"
                  href="#references"
                >
                  Références
                </a>
              </li>
              <li>
                <a
                  className="underline transition duration-300 ease-in-out pointer hover:text-red-500"
                  href="#news"
                >
                  Actualités
                </a>
              </li>
              <li>
                <a
                  className="underline transition duration-300 ease-in-out pointer hover:text-red-500"
                  href={`mailto:${MAIL_TO}`}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="col-span-12 mb-8 md:col-span-6 lg:col-span-3 lg:mb-0">
            <h3 className="mb-6 font-bold tracking-widest uppercase opacity-25">
              Dacso
            </h3>
            <div className="space-y-3 text-sm font-semibold">
              <p className="leading-snug">
                51 rue Gutenberg
                <br />
                75015 Paris,
                <br />
                FRANCE
              </p>
              <p className="leading-snug">
                21 rue Lafaurie de Monbadon
                <br />
                33000 Bordeaux,
                <br />
                FRANCE
              </p>
            </div>
          </div>
          <div className="col-span-12 mb-8 md:col-span-6 lg:col-span-4 lg:mb-0">
            <h3 className="mb-6 font-bold tracking-widest uppercase opacity-25">
              Bureau Chine
            </h3>
            <div className="space-y-2 text-sm font-semibold">
              <p className="leading-snug">
                Two Pacific Place
                <br />
                88 Queensway, Admiralty
                <br />
                Hong Kong,
                <br />
                REPUBLIC OF CHINA
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-6 text-xs text-center text-gray-500 text-gray-700">
        <p>
          Site réalisé par{" "}
          <a className="font-bold" href={URL_FRANCOIS}>
            François Rosato
          </a>
        </p>
      </div>
    </footer>
  );
}
