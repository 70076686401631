import React, { useState, useEffect } from "react"
import cs from "classnames"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import Sidebar from "../components/Sidebar/Sidebar"
import Menu from "../components/Menu/Menu"
import "../styles/variables.css"
import "../styles/tailwind.css"
import "../styles/images.css"

interface IProps {
  className?: string
  children: any
}
export default function Page({ className, children }: IProps) {
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    const cursor = document.querySelector(".cursor")
    document.addEventListener("mousemove", (event) => {
      ;(cursor as any).style.transform = `translate3D(${event.clientX}px, ${event.clientY}px, 0) scale(2.5)`
    })
  }, [])

  return (
    <div className={cs(className, "relative")}>
      <Header />
      <Sidebar opened={opened} setOpened={setOpened} />
      <Menu opened={opened} setOpened={setOpened} />
      <main>{children}</main>
      <Footer />
      <span className="cursor"></span>
    </div>
  )
}
