import cs from "classnames"
import React from "react"
import image_2 from "../../images/image_2.jpg"
import Button from "../Button/Button"
import styles from "./BlockImageText.module.css"

interface IProps {
  id: string
  image: string
  title: string
  subtitle: string
  children: React.ReactNode
  action: {
    label: string
    to: string
  }
  reverse?: boolean
}

export default function BlockImageText({
  id,
  image,
  title,
  subtitle,
  children,
  action,
  reverse = false,
}: IProps) {
  return (
    <section id={id} className="pb-3 mt-40 mb-32">
      <div
        className={cs("container relative flex ", {
          "flex-row": !reverse,
          "flex-row-reverse": reverse,
        })}
      >
        <div
          className={cs(styles.imageWrapper, "w-full lg:w-2/3 absolute", {
            "right-0": !reverse,
            "left-0": reverse,
          })}
        >
          <figure className="max-h-full overflow-hidden scaleDownImageWrapper redFilterImageWrapper">
            <img className="object-cover" src={image} alt="" />
          </figure>
        </div>

        <div className="z-10 w-full p-12 bg-white shadow-2xl lg:w-2/3">
          <div>
            <h2 className="mb-2 text-3xl font-bold leading-none text-gray-800 md:text-5xl">
              {title}
            </h2>
            <span className="flex w-8 h-1 leading-none text-gray-900 bg-red-500"></span>
            <h3 className="mt-2 text-sm font-bold text-gray-700 uppercase md:text-md">
              {subtitle}
            </h3>
          </div>
          <div className="my-8 leading-none text-gray-700 ">{children}</div>
          {action && <Button label={action.label} to={action.to} />}
        </div>
      </div>
    </section>
  )
}
