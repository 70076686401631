import React, { useState } from "react"
import cs from "classnames"
import styles from "./Menu.module.css"
import { motion } from "framer-motion"

export default function Menu({ opened, setOpened }) {
  const wrapper = {
    open: {
      display: "block",
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: [0.17, 0.67, 0.83, 0.67],
        transition: {
          when: "beforeChildren",
        },
      },
    },
    closed: {
      opacity: 0,
      transition: { when: "afterChildren" },
      transitionEnd: { display: "none" },
    },
  }

  const container = {
    closed: { opacity: 0, transition: { when: "afterChildren" } },
    open: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  }

  const item = {
    closed: { opacity: 0, y: 0 },
    open: {
      opacity: 1,
      y: -10,
      transition: { ease: "easeOut", duration: 0.4 },
    },
  }

  return (
    <motion.div
      animate={opened ? "open" : "closed"}
      variants={wrapper}
      className={cs(styles.wrapper, "fixed top-0 bottom-0 left-0 right-0 z-40")}
    >
      <div
        className={cs(
          styles.background,
          { [styles.opened]: opened },
          "absolute top-0 bottom-0 left-0 right-0 bg-gray-900"
        )}
      ></div>
      <div className="relative pt-20">
        <div className="container relative flex flex-col justify-between h-full text-white">
          <div className="relative">
            <div className="w-full mt-16 lg:w-3/5">
              <span className="inline-block font-bold uppercase">
                <h2 className="w-auto">Menu</h2>
                <div className="mt-2 border-2 border-red-500"></div>
              </span>
              <motion.div
                animate={opened ? "open" : "closed"}
                variants={container}
                className={cs(
                  styles.texts,
                  "mt-32 flex flex-col font-bold lg:text-right cursor-pointer leading-normal md:leading-tight"
                )}
              >
                <motion.a
                  variants={item}
                  className="hover:text-red-500"
                  href="#qui-sommes-nous"
                  onClick={() => setOpened(false)}
                >
                  Qui sommes nous ?
                </motion.a>
                <motion.a
                  variants={item}
                  className="hover:text-red-500"
                  href="#notre-philosophie"
                  onClick={() => setOpened(false)}
                >
                  Philosophie
                </motion.a>
                <motion.a
                  variants={item}
                  className="hover:text-red-500"
                  href="#expertises"
                  onClick={() => setOpened(false)}
                >
                  Expertises
                </motion.a>
                <motion.a
                  variants={item}
                  className="hover:text-red-500"
                  href="#zones-influences"
                  onClick={() => setOpened(false)}
                >
                  Zones d'influence
                </motion.a>
                <motion.a
                  variants={item}
                  className="hover:text-red-500"
                  href="#news"
                  onClick={() => setOpened(false)}
                >
                  Actualités
                </motion.a>
              </motion.div>
            </div>
          </div>
        </div>
        <span className={cs(styles.bottomLine)}></span>
      </div>
    </motion.div>
  )
}
