import cs from "classnames"
import React from "react"
import logoSimple from "../../images/logo-simple.svg"
import logo from "../../images/logo.svg"

import styles from "./Header.module.css"

export default function Header() {
  return (
    <header className={cs(styles.wrapper)}>
      <div className="container relative flex items-center h-full">
        <div className="mr-8 text-xl font-black text-red-500">
          <a className={styles.logo} href="/">
            <img src={logo} />
          </a>
        </div>
        {/* <div className="flex items-center text-black opacity-25">
          <span className="mr-2">FR</span>
          <svg width="13" height="9" viewBox="0 0 13 9" fill="none">
            <path d="M1 1L6.5 7L12 1" stroke="currentColor" strokeWidth="2" />
          </svg>
        </div> */}
      </div>
    </header>
  )
}
