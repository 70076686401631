import cs from "classnames";
import React from "react";
import styles from "./BlockCallToAction.module.css";

interface IProps {
  title: string;
  subtitle: string;
  action: {
    label: string;
    to: string;
  };
  image: string;
}

export default function BlockCallToAction({
  title,
  subtitle,
  action,
  image,
}: IProps) {
  return (
    <section className="relative py-16 my-16">
      <div
        className={cs(
          styles.imageWrapper,
          "absolute top-0 bottom-0 left-0 right-0 overflow-hidden bg-red-500"
        )}
      >
        <img
          className={cs(
            styles.image,
            "relative top-0 bottom-0 right-0 left-0 object-cover w-full h-full"
          )}
          src={image}
        />
      </div>
      <div className="container z-10 flex flex-row flex-wrap justify-between">
        <div className="w-full mb-8 text-white lg:w-2/3 lg:mb-0">
          <h3 className="mb-4 text-2xl font-semibold leading-none md:text-5xl">
            {title}
          </h3>
          <p className="text-lg font-semibold leading-tight md:font-regular md:text-xl">
            {subtitle}
          </p>
        </div>
        <div className="flex flex-col justify-end md:mr-16">
          <a
            href={action.to}
            className={cs(
              styles.button,
              "text-xs md:text-sm font-bold inline-flex flex-row items-center w-auto px-10 py-4 uppercase text-red-500 bg-white border-2 border-white"
            )}
          >
            <span>{action.label}</span>
            <svg
              className="ml-5 transition-transform duration-100 ease-in-out transform"
              width="19"
              height="8"
              viewBox="0 0 19 8"
            >
              <path
                d="M18.3194 4.31845C18.5143 4.12282 18.5137 3.80623 18.3181 3.61135L15.13 0.435468C14.9344 0.240581 14.6178 0.241187 14.4229 0.436823C14.228 0.632459 14.2286 0.949041 14.4243 1.14393L17.2581 3.96693L14.4351 6.80077C14.2402 6.99641 14.2408 7.31299 14.4365 7.50788C14.6321 7.70277 14.9487 7.70216 15.1436 7.50652L18.3194 4.31845ZM0.000958067 4.5L17.9662 4.46558L17.9643 3.46558L-0.000958067 3.5L0.000958067 4.5Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
}
