import React from "react"
import cs from "classnames"
import styles from "./Button.module.css"

interface IProps {
  label: string
  to: string
}

export default function Button({ label, to }: IProps) {
  return (
    <a
      className={cs(
        styles.wrapper,
        "flex flex-row items-center text-sm font-extrabold text-red-500 uppercase"
      )}
      href={to}
    >
      <span className="mr-4">{label}</span>
      <svg width="19" height="8" viewBox="0 0 19 8">
        <path
          d="M18.3194 4.31845C18.5143 4.12282 18.5137 3.80623 18.3181 3.61135L15.13 0.435468C14.9344 0.240581 14.6178 0.241187 14.4229 0.436823C14.228 0.632459 14.2286 0.949041 14.4243 1.14393L17.2581 3.96693L14.4351 6.80077C14.2402 6.99641 14.2408 7.31299 14.4365 7.50788C14.6321 7.70277 14.9487 7.70216 15.1436 7.50652L18.3194 4.31845ZM0.000958067 4.5L17.9662 4.46558L17.9643 3.46558L-0.000958067 3.5L0.000958067 4.5Z"
          fill="currentColor"
        />
      </svg>
    </a>
  )
}
