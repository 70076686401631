import React, { useState, useEffect } from "react";
import cs from "classnames";
import { motion, useMotionValue, useTransform } from "framer-motion";
import styles from "./Hero.module.css";

import image_1 from "../../images/image_10.jpg";
import image_2 from "../../images/image_13.jpg";
import image_3 from "../../images/image_7.jpg";

import logo from "../../images/logo-fond.svg";

export default function Hero() {
  const [selected, setSelected] = useState(2);

  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    handleMouse({
      pageX: window.innerWidth / 2,
      pageY: window.innerHeight / 2,
    });
  }, []);

  const image1_x = useMotionValue(10);
  const image1_y = useMotionValue(10);

  const image2_x = useMotionValue(0);
  const image2_y = useMotionValue(0);

  const image3_x = useMotionValue(10);
  const image3_y = useMotionValue(10);

  const transform1_x = useTransform(image1_x, [0, screenSize.width], [-15, 15]);
  const transform1_y = useTransform(
    image1_y,
    [0, screenSize.height],
    [-15, 15]
  );

  const transform2_x = useTransform(image2_x, [0, screenSize.width], [-10, 10]);
  const transform2_y = useTransform(
    image2_y,
    [0, screenSize.height],
    [-10, 10]
  );

  const transform3_x = useTransform(image3_x, [0, screenSize.width], [-20, 20]);
  const transform3_y = useTransform(
    image3_y,
    [0, screenSize.height],
    [-20, 20]
  );

  function handleMouse(event) {
    image1_x.set(event.pageX);
    image2_x.set(event.pageX);
    image3_x.set(event.pageX);

    image1_y.set(event.pageY);
    image2_y.set(event.pageY);
    image3_y.set(event.pageY);
  }

  return (
    <div className={cs(styles.background, "relative")}>
      <div
        onMouseMove={handleMouse}
        className={cs(
          styles.wrapper,
          "flex flex-col justify-between h-full container relative text-gray-800"
        )}
      >
        <div className="w-full mt-16 xl:w-3/5">
          <span className="inline-block font-bold uppercase">
            <h2 className="w-auto cursor-default">We know how</h2>
            <div className="mt-2 border-2 border-red-500"></div>
          </span>
          <h1
            className={cs(
              styles.texts,
              "mt-24 md:mt-48 lg:mt-12 flex flex-col font-bold xl:text-right cursor-default"
            )}
          >
            <span
              onMouseEnter={() => setSelected(1)}
              className={cs(styles.outline, "mb-2")}
            >
              Trading
            </span>
            <span
              onMouseEnter={() => setSelected(2)}
              className="mb-2 text-red-500"
            >
              Business development
            </span>
            <span
              onMouseEnter={() => setSelected(3)}
              className="mb-2 text-gray-800"
            >
              Import / Export
            </span>
          </h1>
        </div>

        <div
          className={cs(
            styles.images,
            styles.image1,
            "absolute transition duration-500 ease-in-out",
            selected === 1 ? "opacity-100" : "opacity-25"
          )}
        >
          <motion.img
            alt=""
            style={{
              x: transform1_x,
              y: transform1_y,
            }}
            src={image_1}
          />
        </div>
        <div
          className={cs(
            styles.images,
            styles.image2,
            "absolute transition duration-500 ease-in-out",
            selected === 2 ? "opacity-100" : "opacity-25"
          )}
        >
          <motion.img
            alt=""
            style={{
              x: transform2_x,
              y: transform2_y,
            }}
            src={image_2}
          />
        </div>
        <div
          className={cs(
            styles.images,
            styles.image3,
            "absolute transition duration-500 ease-in-out",
            selected === 3 ? "opacity-100" : "opacity-25"
          )}
        >
          <motion.img
            alt=""
            style={{
              x: transform3_x,
              y: transform3_y,
            }}
            src={image_3}
          />
        </div>
        <a
          className="relative flex flex-row items-center mt-12 mb-24 opacity-75"
          href="#qui-sommes-nous"
        >
          <span className="text-sm font-bold uppercase">En savoir plus</span>
          <span className="mt-2 ml-4 animate-bounce">
            <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none">
              <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
              <path
                d="M11.6464 17.3191C11.8417 17.5144 12.1583 17.5144 12.3536 17.3191L15.5355 14.1372C15.7308 13.9419 15.7308 13.6253 15.5355 13.43C15.3403 13.2348 15.0237 13.2348 14.8284 13.43L12 16.2585L9.17157 13.43C8.97631 13.2348 8.65973 13.2348 8.46447 13.43C8.2692 13.6253 8.2692 13.9419 8.46447 14.1372L11.6464 17.3191ZM11.5 7.03455L11.5 16.9656L12.5 16.9656L12.5 7.03455L11.5 7.03455Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </a>
      </div>
      <span className={cs(styles.bottomLine)}></span>
    </div>
  );
}
