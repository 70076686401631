import cs from "classnames"
import React from "react"
import styles from "./BlockColumns.module.css"

interface IProps {
  id: string
  title: string
  subtitle?: string
  text?: React.ReactNode
  columns: {
    title: string
    subtitle: string
    text: React.ReactNode
    image: string
    // action?: {
    //   label: string
    //   to: string
    // }
  }[]
}

export default function BlockColumns({
  id,
  title,
  subtitle,
  text,
  columns,
}: IProps) {
  return (
    <section id={id} className="my-16">
      <div className="container">
        <div className="mb-16">
          <h2 className="mb-2 text-5xl font-bold leading-none text-gray-800">
            {title}
          </h2>
          <span className="flex w-8 h-1 leading-none text-gray-900 bg-red-500"></span>
          {subtitle && (
            <h3 className="mt-2 mb-6 font-bold text-gray-700 uppercase text-md">
              {subtitle}
            </h3>
          )}
          {text && <p className="text-gray-600 lg:w-2/3">{text}</p>}
        </div>
        <div className="grid grid-cols-1 gap-12">
          {columns.map((column, index) => (
            <article
              key={`BlockColumns_${index}`}
              className={cs(styles.article)}
            >
              <figure className="relative w-full scaleDownImageWrapper redFilterImageWrapper">
                <img
                  className="object-cover w-full h-full"
                  src={column.image}
                  alt={column.title}
                />
              </figure>
              <div className="w-11/12 mx-auto">
                <h1 className="mt-6 mb-2 text-2xl font-bold leading-none text-gray-800">
                  {column.title}
                </h1>
                <span className="flex w-8 h-1 leading-none text-gray-900 bg-red-500"></span>
                <h2 className="mt-2 mb-6 text-sm font-bold text-gray-700 uppercase text-md">
                  {column.subtitle}
                </h2>
                <div className="mb-6 text-gray-600">{column.text}</div>
                {/* {column.action && (
                <Button label={column.action.label} to={column.action.to} />
              )} */}
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  )
}
