import React, { useState } from "react"
import cs from "classnames"
import styles from "./Sidebar.module.css"
import { MAIL_TO, URL_LINKEDIN } from "../../constants"

export default function Sidebar({ setOpened, opened }) {
  return (
    <aside
      className={cs(
        styles.wrapper,
        "fixed top-0 bottom-0 right-0 flex flex-col justify-between z-50 transition-colors ease-in duration-100 antialiased",
        {
          ["bg-white text-gray-900"]: !opened,
          ["text-white"]: opened,
        }
      )}
    >
      <div
        className={cs(styles.header, "w-full items-center justify-center flex")}
      >
        {/* https://uxdesign.cc/the-menu-210bec7ad80c */}
        <button
          className={cs(styles.burger, "cursor-pointer outline-none z-50", {
            [styles.burger_opened]: opened,
          })}
          onClick={() => setOpened(!opened)}
          aria-expanded={opened}
          aria-label="Main Menu"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 100 100"
            stroke={opened ? "#fff" : "#1a202c"}
          >
            <path d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
            <path d="M 20,50 H 80" />
            <path d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
          </svg>
        </button>
      </div>
      {/* <div
        className={cs(
          "text-xl font-black translate-x-0 rotate-90 transform text-white opacity-0 transition-all delay-300 duration-500 antialiased",
          {
            ["opacity-100"]: opened,
          }
        )}
      >
        DACSO
      </div> */}
      <div>
        <div
          className={cs(
            "flex justify-center pb-4 transition-colors ease-in duration-200 hover:text-gray-900",
            {
              ["text-red-500"]: !opened,
              ["text-white"]: opened,
            }
          )}
        >
          <a className="p-2" href={URL_LINKEDIN}>
            <svg width="28" height="28" viewBox="0 0 28 28">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3072 11.3747H15.0123V12.7609H15.0543C15.4323 12.0804 16.3564 11.3747 17.7258 11.3747C20.5821 11.3747 21.1113 13.1557 21.1113 15.4743V20.1872H18.2886V16.0036C18.2886 15.0039 18.2718 13.727 16.8185 13.727C15.3483 13.727 15.1215 14.8107 15.1215 15.9364V20.1872H12.2988V11.3747H12.3072ZM14.0042 0C17.8686 0 21.3717 1.57096 23.9088 4.09961C26.4458 6.63666 28.0084 10.1314 28.0084 14.0042C28.0084 17.8686 26.4374 21.3717 23.9088 23.9088C21.3717 26.4458 17.877 28.0084 14.0042 28.0084C10.1398 28.0084 6.63666 26.4374 4.09961 23.9088C1.56256 21.3717 0 17.877 0 14.0042C0 10.1398 1.57096 6.63666 4.09961 4.09961C6.63666 1.56256 10.1314 0 14.0042 0ZM23.1863 4.81368C20.8341 2.46145 17.5914 1.0081 14.0042 1.0081C10.417 1.0081 7.16592 2.46145 4.82208 4.81368C2.46985 7.16592 1.0165 10.4086 1.0165 13.9958C1.0165 17.583 2.46985 20.8341 4.82208 23.1779C7.17432 25.5302 10.417 26.9835 14.0042 26.9835C17.5914 26.9835 20.8425 25.5302 23.1863 23.1779C25.5386 20.8257 26.9919 17.583 26.9919 13.9958C26.9919 10.4086 25.5386 7.15752 23.1863 4.81368ZM10.3498 8.93009C10.3498 9.74498 9.69457 10.4002 8.87969 10.4002C8.06481 10.4002 7.40954 9.74498 7.40954 8.93009C7.40954 8.11521 8.06481 7.45995 8.87969 7.45995C9.69457 7.45995 10.3498 8.11521 10.3498 8.93009ZM7.40954 11.3747H10.3498V20.1872H7.40954V11.3747Z"
                fill="currentColor"
              />
              <defs>
                <clipPath id="clip0">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div
          className={cs(
            styles.mail,
            "bg-red-500  pt-4 text-center hover:text-gray-900 text-white transition-colors ease-in duration-200"
          )}
        >
          <a className="inline-block p-2" href={`mailto:${MAIL_TO}`}>
            <svg width="22" height="19" viewBox="0 0 22 19">
              <path
                d="M0 18.2225H22V0H0V18.2225ZM11 12.1343L13.1507 10.0522L20.0684 16.7647H1.9316L8.84903 10.0522L11 12.1343ZM14.1312 9.10285L20.7429 2.70166V15.5186L14.1312 9.10285ZM11 10.2352L1.93411 1.4578H20.0662L11 10.2352ZM7.86877 9.10285L1.25714 15.5186V2.70166L7.86877 9.10285Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      </div>
    </aside>
  )
}
