import React from "react"
import cs from "classnames"
import partners from "../../images/partners.png"
import styles from "./BlockDual.module.css"

interface IProps {
  items: {
    title: string
    subtitle: string
    action?: {
      label: string
      to: string
    }
    image: string
    children: React.ReactNode
  }[]
}

export default function BlockDual({ items }: IProps) {
  const [first, second] = items
  return (
    <section className="my-16">
      <div className="container">
        <div className="shadow-2xl">
          <div className="grid flex-col-reverse grid-col-1 lg:grid-cols-2">
            <div
              className={cs(styles.imageWrapper, "overflow-hidden bg-red-500")}
            >
              <figure className="relative w-full h-full redFilterImageWrapper">
                <img
                  className="object-cover w-full h-full duration-300 transform scale-125 hover:scale-100"
                  src={first.image}
                />
              </figure>
            </div>
            <div className="h-full p-8 text-gray-900 bg-gray-200">
              <h3 className="my-4 text-2xl font-semibold leading-none md:text-4xl">
                {first.title}
              </h3>
              <h2 className="mb-8 text-sm font-bold uppercase">
                {first.subtitle}
              </h2>
              <div className="mb-8">{first.children}</div>
              {first.action && (
                <div className="inline-flex flex-col justify-end mr-16">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={first.action.to}
                    className={cs(
                      styles.button,
                      "text-sm font-bold inline-flex flex-row items-center w-auto px-10 py-4 uppercase text-white bg-red-500 border-2 border-red-500"
                    )}
                  >
                    <span>{first.action.label}</span>
                    <svg
                      className="ml-5 transition-transform duration-100 ease-in-out transform"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                    >
                      <path
                        d="M18.3194 4.31845C18.5143 4.12282 18.5137 3.80623 18.3181 3.61135L15.13 0.435468C14.9344 0.240581 14.6178 0.241187 14.4229 0.436823C14.228 0.632459 14.2286 0.949041 14.4243 1.14393L17.2581 3.96693L14.4351 6.80077C14.2402 6.99641 14.2408 7.31299 14.4365 7.50788C14.6321 7.70277 14.9487 7.70216 15.1436 7.50652L18.3194 4.31845ZM0.000958067 4.5L17.9662 4.46558L17.9643 3.46558L-0.000958067 3.5L0.000958067 4.5Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="grid flex-col-reverse grid-col-1 lg:grid-cols-2">
            <div className="h-full p-8 text-white bg-gray-900">
              <h3 className="my-4 text-2xl font-semibold leading-none md:text-4xl">
                {second.title}
              </h3>
              <h2 className="mb-8 text-sm font-bold uppercase">
                {second.subtitle}
              </h2>
              <div className="mb-8">{second.children}</div>
              {second.action && (
                <div className="inline-flex flex-col justify-end mr-16">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={second.action.to}
                    className={cs(
                      styles.button,
                      "text-sm font-bold inline-flex flex-row items-center w-auto px-10 py-4 uppercase text-white bg-red-500 border-2 border-red-500"
                    )}
                  >
                    <span>{second.action.label}</span>
                    <svg
                      className="ml-5 transition-transform duration-100 ease-in-out transform"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                    >
                      <path
                        d="M18.3194 4.31845C18.5143 4.12282 18.5137 3.80623 18.3181 3.61135L15.13 0.435468C14.9344 0.240581 14.6178 0.241187 14.4229 0.436823C14.228 0.632459 14.2286 0.949041 14.4243 1.14393L17.2581 3.96693L14.4351 6.80077C14.2402 6.99641 14.2408 7.31299 14.4365 7.50788C14.6321 7.70277 14.9487 7.70216 15.1436 7.50652L18.3194 4.31845ZM0.000958067 4.5L17.9662 4.46558L17.9643 3.46558L-0.000958067 3.5L0.000958067 4.5Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              )}
            </div>
            <div className="relative overflow-hidden bg-white">
              <figure className="relative w-full h-full">
                <img
                  className="object-cover w-full h-full duration-300 transform scale-125 opacity-25 hover:scale-100"
                  src={second.image}
                />
              </figure>
              <img
                className="absolute top-0 bottom-0 left-0 right-0 z-20 m-auto select-none"
                src={partners}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
