import { MAIL_TO, URL_LINKEDIN } from "../constants";
import React, { useEffect } from "react";

import BlockCallToAction from "../components/BlockCallToAction/BlockCallToAction";
import BlockColumns from "../components/BlockColumns/BlockColumns";
import BlockColumnsCircles from "../components/BlockColumnsIcons/BlockColumnsIcons";
import BlockDual from "../components/BlockDual/BlockDual";
import BlockImageText from "../components/BlockImageText/BlockImageText";
import BlockReference from "../components/BlockReference/BlockReference";
import { Helmet } from "react-helmet";
import Hero from "../components/Hero/Hero";
import Page from "../layout/Page";
import image_1 from "../images/image_1.jpg";
import image_11 from "../images/image_11.jpg";
import image_2 from "../images/image_2.jpg";
import image_3 from "../images/image_3.jpg";
import image_7 from "../images/image_7.jpg";
import image_8 from "../images/image_8.jpg";
import image_9 from "../images/image_9.jpg";
import jed from "../images/jed.jpg";
import news_5 from "../images/news_5.jpg";
import news_1 from "../images/news_1.jpg";
import news_6 from "../images/news_6.jpg";

export default function Index() {
  // Smooth Scroll
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <Page>
      <Helmet>
        <html lang="fr" />
        <meta charset="utf-8" />
        <title>Dacso | Trading, Business Development, Procurement</title>
        <meta
          name="description"
          content="Trading, import export de matériels et matériaux au départ de l'Asie du Sud-Est et en direction de la France et des pays francophones. Développement des affaires et externalisation commerciale."
        />
        <link rel="canonical" href="http://dacso.fr" />
      </Helmet>
      <Hero />
      <BlockImageText
        id="qui-sommes-nous"
        image={image_8}
        title="Qui sommes nous ?"
        subtitle="Nous pouvons vous accompagner"
        action={{
          label: "Notre philosophie",
          to: "#notre-philosophie",
        }}
      >
        <p className="mb-8">
          DACSO est une société commerciale, de courtage et de trading physique,
          spécialisée dans le négoce des métaux ferreux et non ferreux.
        </p>
        <h4 className="mb-4 font-bold">
          Trading-Procurement &amp; Business Development
        </h4>
        <h5 className="mb-4">Notre valeur ajoutée :</h5>
        <ul className="text-xs leading-tight list-disc list-inside list md:text-base">
          <li>L’Import-Export des Aciers.</li>
          <li>Le renfort de votre impact Achat.</li>
          <li>L’apport de notre réseau Industriel International.</li>
          <li>L’accessibilité aux opportunités de Trading Physique.</li>
          <li>L’expertise et la gestion de la Supply Chain.</li>
          <li>La recherche et la Négociation des Affaires.</li>
          <li>Le développement de vos Intérêts Commerciaux.</li>
        </ul>
      </BlockImageText>

      <BlockImageText
        id="notre-philosophie"
        reverse
        image={image_9}
        title="Notre philosophie"
        subtitle="15 Années d'expertise au service de nos clients"
        action={{
          label: "Nos expertises",
          to: "#expertises",
        }}
      >
        <p className="mb-8 leading-relaxed">
          Intégrité, éthique, responsabilité.
          <br />
          Nous considérons la <b>satisfaction des clients</b> comme notre
          première priorité.
          <br />
          Nous croyons à la fédération des compétences.
          <br />
          Performance et esprit de <b>partenariat</b> caractérisent notre style
          de travail.
          <br />
          Nous répondons aux besoins de nos clients avec clairvoyance,{" "}
          <b>implication et professionalisme</b>.
          <br />
          <br />
          <i className="leading-normal block">
            « Les deux choses les plus importantes n’apparaissent pas au bilan
            de l’entreprise : sa réputation et ses hommes. »{" "}
            <strong>H.Ford</strong>
          </i>
        </p>
      </BlockImageText>

      <BlockColumns
        id="expertises"
        title="Expertises"
        columns={[
          {
            title: "Import Export",
            subtitle: "Le fer de lance de vos achats",
            text: (
              <div>
                <h3 className="mb-8 leading-tight">
                  Au fil du temps, nous avons établi des relations fortes avec
                  un vaste réseau de fournisseurs industriels en Asie du
                  sud-est, permettant d’offrir à nos clients une variété
                  exceptionnelle de choix de produits et de services.{" "}
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  <div className="mb-4">
                    <h4 className="mb-2 font-bold leading-tight">
                      Importateurs depuis la France,
                      <br />
                      Exportateurs depuis l’Asie :
                    </h4>
                    <ul className="text-xs leading-tight list-disc list-inside list md:text-base">
                      <li>Vietnam,</li>
                      <li>Chine, </li>
                      <li>Malaisie,</li>
                      <li>Taiwan,</li>
                      <li>...</li>
                    </ul>
                  </div>
                  <div className="mb-4">
                    <h4 className="mb-2 font-bold leading-none">
                      Secteurs d'activité :
                    </h4>
                    <ul className="text-xs leading-tight list-disc list-inside list md:text-base">
                      <li className="mb-2 leading-none">BTP</li>
                      <li className="mb-2 leading-none">
                        Distribution Spécialisée
                      </li>
                      <li>
                        <span className="mb-2 leading-none">Industrie :</span>
                        <ul className="text-xs leading-tight list-inside list md:text-base pl-4">
                          <li>Agro-alimentaire,</li>
                          <li>Chimique,</li>
                          <li>Pharmaceutique,</li>
                          <li>Cosmétique,</li>
                          <li>Oil &amp; Gas,</li>
                          <li>EnR,</li>
                          <li>Navale,</li>
                          <li>Minière,</li>
                          <li>Automobile.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="mb-4">
                    <h4 className="mb-2 font-bold leading-none">
                      Fournitures :
                    </h4>
                    <ul className="text-xs leading-tight list-disc list-inside list md:text-base">
                      <li>
                        <span className="mb-2 leading-none">
                          Métaux ferreux et non ferreux :
                        </span>
                        <ul className="text-xs leading-tight list-inside list md:text-base pl-4">
                          <li>Inox,</li>
                          <li>Aluminium,</li>
                          <li>Acier,</li>
                          <li>Cuivre (scrap et cathode de cuivre),</li>
                          <li>Zinc,</li>
                          <li>Nickel,</li>
                          <li>Minerai de fer.</li>
                        </ul>
                        <div
                          className="my-2"
                          style={{ fontSize: "10px", lineHeight: "12px" }}
                        >
                          En plat - coil - tôle - tube - cornière - profilé -
                          barre - poutre - lingot - billette - Produit parachevé
                          - brut - tôles galvanisées.
                        </div>
                      </li>
                      <li>Tréfilage </li>
                      <li>Armature métallique</li>
                      <li>Visserie, boulonnerie, fixation</li>
                      <li>Bardage métallique</li>
                    </ul>
                  </div>
                </div>
              </div>
            ),
            image: image_7,
          },
          {
            title: "Développement Commercial",
            subtitle: "Un accès à votre réussite",
            text: (
              <div className="grid grid-cols-1 gap-8">
                <p>
                  <b>
                    Nous représentons les intérêts commerciaux de partenaires
                  </b>{" "}
                  industriels et de la transformation des Aciers, désireux de
                  consolider leur position sur le marché français ou à
                  l’international.
                  <br />
                  <br />
                  En renfort, nous devenons à travers la mission d’achat ou de
                  vente, un acteur opérationnel de leur stratégie de
                  développement.
                  <br />
                  <br />
                  <b>Nous formons un binôme</b> aux valeurs partagées sur le
                  travail, l’environnement économique et l’éco-responsabilité.
                </p>
              </div>
            ),
            image: image_11,
          },

          {
            title: "Gestion de la Supply Chain",
            subtitle: "La valeur ajoutée de vos approvisionements",
            text: (
              <div>
                <p className="mb-4">
                  Gestion du processus d&#39;approvisionnement de la production
                  à la livraison.
                </p>
                <ul className="text-xs leading-tight list-disc list-inside list md:text-base">
                  <li>Gestion des fournisseurs,</li>
                  <li>Gestion des accords de prix et des contrats,</li>
                  <li>Contrôle Qualité,</li>
                  <li>Entreposage,</li>
                  <li>Fret aérien, maritime, ferroviaire et expédition,</li>
                  <li>Dédouanement, transitaires,</li>
                  <li>
                    Assistance technique pour l&#39;installation des
                    équipements,
                  </li>
                  <li>
                    Services de suivi des produits, garantie et
                    approvisionnement SAV.
                  </li>
                </ul>
              </div>
            ),
            image: image_2,
          },
        ]}
      />
      <BlockReference
        id="references"
        image={image_3}
        title="Références chantiers"
        subtitle="Acier et Approvisionnement"
      >
        <div className="grid grid-cols-1 font-bold lg:grid-cols-4 md:grid-cols-2">
          <ul>
            <li>Métro lignes A&amp;B Toulouse</li>
            <li>Airbus hangar C65 Toulouse</li>
            <li>Stade de Lille</li>
            <li>Stade de Bordeaux</li>
            <li>Stade de Lyon</li>
          </ul>
          <ul>
            <li>Tour Descartes Paris La Défense</li>
            <li>Tour Skylight Paris La Défense</li>
            <li>IESEG Paris La Défense </li>
            <li>U-Aréna Paris La Défense</li>
            <li>Centrale EDF Bouchain</li>
          </ul>
          <ul>
            <li>Gare Belcier de Bordeaux </li>
            <li>Urbalad Michelin Clermont.F</li>
            <li>Terminal Méthanier Dunkerque</li>
            <li>Docks de Marseille</li>
            <li>Centrale EDF Lucciana</li>
          </ul>
          <ul>
            <li>Euromed-Center2 Marseille </li>
            <li>Eurocopter Paris-Le Bourget</li>
            <li>Usine Renault Tanger Maroc</li>
            <li>Port autonome de Lomé Togo</li>
            <li>Gare de triage Toulouse</li>
          </ul>
        </div>
      </BlockReference>

      <BlockColumnsCircles
        id="zones-influences"
        title="Zones d'influences"
        text={
          <>
            Dacso est implanté à <b>Paris et Bordeaux</b> et travaille sur
            l'ensemble du territoire. Nous avons de nombreux relais industriels
            et des bureaux de sourcing au <b>Vietnam</b>, en <b>Chine</b>, en{" "}
            <b>Malaisie</b>. Dacso a une expertise commerciale importante sur le
            Portugal, l'Espagne et les pays de l'Afrique de l'Ouest (
            <b>CEDEAO</b> et <b>CEEAC</b>).
          </>
        }
        columns={[
          {
            title: "France",
            subtitle: "Continental et Dom-Tom",
            image: image_1,
          },
          {
            title: "Afrique",
            subtitle: "Francophone",
            image: image_1,
          },
          {
            title: "Asie",
            subtitle: "du Sud Est",
            image: image_1,
          },
        ]}
      />
      <BlockDual
        items={[
          {
            image: jed,
            title: "Président Directeur Général",
            subtitle: "Jean Emmanuel Devienne",
            action: {
              label: "Linkedin",
              to: URL_LINKEDIN,
            },
            children: (
              <p>
                Jean-Emmanuel Devienne est né à Lille. <br />
                Après un parcours dans <b>deux grands groupes internationaux</b>
                , il crée DACSO en 2005 et développe son activité en France, au
                Portugal et dans les pays de l’Afrique de l'Ouest.
                <br /> Il est aussi{" "}
                <b>
                  membre du comité d'orientation stratégique de EIC Corporation
                </b>
                , Do-Tank de coopération économique internationale dont il est
                le{" "}
                <b>
                  responsable des relations partenaires et institutionnelles
                </b>
                .
              </p>
            ),
          },
          {
            image: image_8,
            title: "Nos Partenaires",
            subtitle: "L'asie au bout des doigts",
            children: (
              <p>
                Pour répondre aux besoins de business development, de sourcing
                et de procurement des entreprises, <b>Dacso</b> a uni ses forces
                à celles de partenaires industriels et commerciaux afin
                d’apporter à ses clients un service optimal au meilleur coût.
              </p>
            ),
          },
        ]}
      />

      <BlockCallToAction
        title="Vous avez un projet ?"
        subtitle="Nous sommes à l'écoute et disposés à vous rencontrer"
        image={image_1}
        action={{
          label: "Prendre rendez-vous",
          to: `mailto:${MAIL_TO}`,
        }}
      />

      <section
        id="news"
        className="mb-24 overflow-hidden text-gray-700 bg-white"
      >
        <div className="container">
          <div className="mb-16">
            <h2 className="mb-2 text-3xl font-bold leading-none text-gray-800 md:text-5xl">
              Actualités
            </h2>
            <span className="flex w-8 h-1 mb-2 leading-none text-gray-900 bg-red-500"></span>
          </div>
          <div className="-my-8">
            <div className="flex flex-wrap py-8 space-x-5 border-t-2 border-gray-200 md:flex-no-wrap">
              <div className="flex flex-col flex-shrink-0 w-full mb-6 md:w-64 md:mb-0">
                <span className="font-medium tracking-widest text-gray-900 title-font">
                  ARTICLE
                </span>
                <span className="mb-1 text-sm text-gray-500">Juin 2021</span>
                <img className="w-full" src={news_6} alt="Image news" />
              </div>
              <div className="md:flex-grow">
                <h2 className="mb-2 text-2xl font-medium text-gray-900 title-font">
                  DACSO conforte son action dans le trading des métaux.
                </h2>
                <p className="leading-relaxed">
                  Après l'ACIER et le CUIVRE, l'INOX et l'ALUMINIUM avec de
                  nouveaux marchés importants de lingots d'aluminium A7 99,7%
                  purity et billets 6063 T5 pour l'extrusion destinés à la
                  fonderie en Europe et de tôles d'inox brossé 304 et 441 pour
                  approvisionner l'industrie française du mobilier professionnel
                  de cuisine et autres équipements alimentaires.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap py-8 space-x-5 md:flex-no-wrap">
              <div className="flex flex-col flex-shrink-0 w-full mb-6 md:w-64 md:mb-0">
                <span className="font-medium tracking-widest text-gray-900 title-font">
                  ARTICLE
                </span>
                <span className="mb-1 text-sm text-gray-500">Mai 2021</span>
                <img className="w-full" src={news_1} alt="Image news 1" />
              </div>
              <div className="md:flex-grow">
                <h2 className="mb-2 text-2xl font-medium text-gray-900 title-font">
                  Pénurie des matières premières.
                </h2>
                <p className="leading-relaxed">
                  Pour un client français, en pleine pénurie d'acier et autres
                  matières premières, nous avons réalisé l'exploit de sourcer et
                  lui livrer de Chine et du Vietnam un premier jet de 1 500
                  tonnes de tréfilage (barres d'acier et bobines de fil B500B,
                  treillis soudés, crosses d'ancrage) pour un prix unitaire
                  rendu CIF Le Havre de 750$/tonne.
                  <br />
                  Ce premier arrivage sera reconduit si l'opération se couronne
                  de succès, ce dont nous ne doutons pas.
                  <br />
                  <br />
                  <strong>
                    Si vous avez des besoins en Acier brut ou transformé, PRS,
                    HEB, IPN, CUIVRE, INOX, fournitures bâtiment, contactez nous
                    !
                  </strong>
                </p>
                <a
                  className="inline-flex items-center mt-4 text-red-800"
                  href={`mailto:${MAIL_TO}`}
                >
                  Nous contacter
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="flex flex-wrap py-8 space-x-5 border-t-2 border-gray-200 md:flex-no-wrap">
              <div className="flex flex-col flex-shrink-0 w-full mb-6 md:w-64 md:mb-0">
                <span className="font-medium tracking-widest text-gray-900 title-font">
                  ARTICLE
                </span>
                <span className="mb-1 text-sm text-gray-500">Février 2021</span>
                <img className="w-full" src={news_5} alt="Image news" />
              </div>
              <div className="md:flex-grow">
                <h2 className="mb-2 text-2xl font-medium text-gray-900 title-font">
                  Livraison d'aciers spéciaux
                </h2>
                <p className="leading-relaxed">
                  Nous allons livrer au Bénin et au Niger une première tranche
                  de 800 tonnes d'acier transformé, sous forme de PRS de plus de
                  12m et des rotules de soutenement en acier spécial S690QL à
                  chevilles métalliques de 150mm d'épaisseur. Cette opération
                  démontre notre capacité à répondre aux défis que présente
                  régulièrement le continent Afrique en termes de travaux et de
                  livraisons de matériaux de haute qualité technique.
                </p>
                {/* <a
                  className="inline-flex items-center mt-4 text-red-800"
                  href={`mailto:${MAIL_TO}`}
                >
                  Nous contacter
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a> */}
              </div>
            </div>
            <div className="flex flex-wrap py-8 space-x-5 border-t-2 border-gray-200 md:flex-no-wrap">
              <div className="flex flex-col flex-shrink-0 mb-6 md:w-64 md:mb-0">
                <span className="font-medium tracking-widest text-gray-900 title-font">
                  ARTICLE
                </span>
                <span className="mb-1 text-sm text-gray-500">Mai 2020</span>
              </div>
              <div className="md:flex-grow">
                <h2 className="mb-2 text-2xl font-medium text-gray-900 title-font">
                  Covid-19 - Afrique
                </h2>
                <p className="leading-relaxed">
                  "La tokénisation des actifs, un moyen pour révolutionner le
                  financement des PME/TPE".
                </p>
                <a
                  className="inline-flex items-center mt-4 text-red-800"
                  href="https://www.africapresse.paris/Covid-19-Afrique-La-tokenisation-des-actifs-un-moyen-pour-revolutionner-le"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Lire l'article
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
            {/* <div className="flex flex-wrap py-8 space-x-5 border-t-2 border-gray-200 md:flex-no-wrap">
              <div className="flex flex-col flex-shrink-0 w-full mb-6 md:w-64 md:mb-0">
                <img className="w-full" src={news_4} alt="Image news 3" />
              </div>
              <div className="md:flex-grow">
                <h2 className="mb-2 text-2xl font-medium text-gray-900 title-font">
                  Covid-19 - Le masque FFP2
                </h2>
                <p className="leading-relaxed">
                  Le masque FFP2 fortement conseillé pour lutter efficacement
                  contre la propagation du variant Covid-19.
                  <br />
                  Entreprises, distributeurs, prenez vos dispositions. Nous
                  avons les meilleurs prix directs usines Vietnam. Nous assurons
                  l’approvisionnement Delivred At Place jusqu’aux portes de vos
                  entrepôts, si nécessaire. Nos bureaux à Hô Chi Minh City, Hong
                  Kong mais aussi à Bordeaux et Paris, vous assurent un service
                  de haute qualité.
                  <br />
                  <br />
                  Droit de douane avec COO : 0% (convention Franco-Vietnamienne)
                  <br />
                  <br />
                  HS CODE 63079090D
                  <br />
                  <br />
                  Capacité de production actuelle de plus de 700 000 FFP2 par
                  jour, départ du Vietnam.
                  <br />
                  <br />
                  CIF maritime Le Havre ou Marseille 35 jours
                  <br />
                  CIF aérien Paris CDG 6 jours.
                </p>
                <a
                  className="inline-flex items-center mt-4 text-red-800"
                  href={`mailto:${MAIL_TO}`}
                >
                  Nous contacter
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </Page>
  );
}
