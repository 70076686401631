import cs from "classnames"
import React from "react"
import styles from "./BlockReference.module.css"

interface IProps {
  id: string
  title: string
  subtitle: string
  children: React.ReactNode
  image: string
}

export default function BlockReference({
  id,
  children,
  title,
  subtitle,
  image,
}: IProps) {
  return (
    <section id={id} className="relative py-16 my-16">
      <div
        className={cs(
          styles.imageWrapper,
          "absolute top-0 bottom-0 left-0 right-0 overflow-hidden bg-red-600"
        )}
      >
        <img
          className={cs(
            styles.image,
            "relative top-0 bottom-0 right-0 left-0 object-cover w-full h-full"
          )}
          src={image}
        />
      </div>
      <div className="container z-10 text-white">
        <h2 className="mb-2 text-3xl font-bold leading-none md:text-5xl">
          {title}
        </h2>
        <span className="flex w-8 h-1 leading-none bg-white"></span>
        {subtitle && (
          <h3 className="mt-2 mb-8 font-bold uppercase text-md">{subtitle}</h3>
        )}
        <div className="mb-8">{children}</div>
      </div>
    </section>
  )
}
